html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

body {
  color: #fff;
  background: linear-gradient(135deg, #070707, #6c6a6a 50%, #e2bc72 50%, #ca9d4a) fixed;
  flex-direction: column;
  min-height: 100vh;
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
  display: flex;
}

#container {
  padding: 15px;
}

#footer {
  color: #fffc;
  border-top: 1px solid #fff9;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px 15px 10px 0;
  font-size: .75em;
  font-weight: normal;
  display: flex;
}

#footer a {
  cursor: pointer;
}

#footer a:hover {
  text-decoration: underline;
}

.logo {
  justify-content: center;
  margin-bottom: 5px;
  display: flex;
}

.logo img {
  width: 95%;
  max-width: 500px;
}

.instructions {
  text-align: center;
  font-size: 1.35em;
}

.option {
  background: #fff3;
  border: 1px solid #fff9;
  margin: 15px 0 8px;
  padding: 10px;
}

.option .topLine {
  align-items: center;
  line-height: 1.2;
  display: flex;
}

.option .vote {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background-color: #fa9d08;
  max-width: 0;
  margin-right: 10px;
  padding: 10px 0;
  font-size: 1.5em;
  font-weight: 900;
  overflow: hidden;
  box-shadow: 3px 3px 3px #0003;
}

.novote .option .vote {
  border: 1px solid #fa9d08;
  max-width: 150px;
  padding-left: 10px;
  padding-right: 10px;
}

.options .vote:hover {
  color: #fff;
  background: #ca9d4a;
  box-shadow: 1px 1px 1px #0009;
}

.options .vote:active {
  color: #fff;
  background: #ca9d4a;
  box-shadow: inset 1px 1px 1px #000c;
}

.option .header {
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 900;
}

.option .subhead {
  font-size: 1.1em;
}

.option .thumbnail img {
  width: 100%;
  margin-top: 10px;
}

.terms {
  color: #fff9;
  display: none;
}

.terms.show {
  display: block;
}

.voted {
  max-width: 0;
  overflow: hidden;
}

.checkmark__circle {
  stroke-dasharray: 216;
  stroke-dashoffset: 216px;
  stroke-width: 2px;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
}

.checkmark {
  stroke-width: 2px;
  stroke: #fff;
  stroke-miterlimit: 10;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin: 10% auto;
  display: block;
  box-shadow: inset 0 0 #7ac142;
}

.checkmark__check {
  transform-origin: 50%;
  stroke-dasharray: 98;
  stroke-dashoffset: 98px;
}

.options.done .yes .voted .checkmark__circle {
  animation: .6s cubic-bezier(.65, 0, .45, 1) forwards stroke;
}

.options.done .yes .voted .checkmark {
  animation: .4s ease-in-out .4s forwards fill, .3s ease-in-out .9s both scale;
}

.options.done .yes .voted .checkmark__check {
  animation: .3s cubic-bezier(.65, 0, .45, 1) .8s forwards stroke;
}

.options.done .vote {
  border: 0;
  width: 0;
  padding-left: 0;
  padding-right: 0;
  transition: all .3s;
  overflow: hidden;
}

.options.done .yes .voted {
  max-width: 200px;
  overflow: hidden;
}

#follow {
  text-align: center;
  background-color: #fa9d0899;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  padding: 20px;
  display: flex;
}

#follow .click {
  font-size: 1.5em;
  font-weight: bold;
}

#follow a {
  color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  text-decoration: none !important;
}

#follow a div {
  font-size: 18px;
}

#follow img {
  height: 36px;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 80px #7ac142;
  }
}

.hidden, [hidden] {
  display: none !important;
}

.visually-hidden {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visually-hidden.focusable:active, .visually-hidden.focusable:focus {
  clip: auto;
  white-space: inherit;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media only screen and (width >= 1024px) {
  body {
    font-size: 1.25em;
  }

  #container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .options {
    justify-content: center;
    margin-top: 10px;
    display: flex;
  }

  .options .option {
    margin: 10px;
    box-shadow: 3px 3px 3px #0003;
  }

  #terms {
    max-width: 900px;
  }
}

/*# sourceMappingURL=index.2972cc54.css.map */
