html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/* ==========================================================================
     Author's custom styles
     ========================================================================== */

body {
  background: linear-gradient(
      135deg,
      
      #070707,
      #6c6a6a 50%,
      #e2bc72 50%,
      #ca9d4a
      
    )
    fixed;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#container {
  padding: 15px;
}

#footer {
  margin-top: auto;
  padding: 10px 15px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75em;
  font-weight: normal;
  border-top: solid 1px #ffffff99;
  margin-left: 15px;
  margin-right: 15px;
  color: #ffffffcc;
}

#footer a {
  cursor: pointer;
}

#footer a:hover {
  text-decoration: underline;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.logo img {
  width: 95%;
  max-width: 500px;
}

.instructions {
  text-align: center;
  font-size: 1.35em;
}

.option {
  border: solid 1px #ffffff99;
  background: rgba(255,255,255,.2);
  padding: 10px;
  margin: 15px 0 8px;
}

.option .topLine {
  display: flex;
  align-items: center;
  line-height: 1.2;
}
.option .vote {
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 900;
  background-color: #fa9d08;
  padding-top:10px;
  padding-bottom: 10px;
  padding-left:0;
  padding-right:0;
  margin-right: 10px;
  color: #ffF;
  box-shadow: #00000033 3px 3px 3px;
  cursor: pointer;
  max-width: 0;
  overflow: hidden;
}

.novote .option .vote {
  max-width: 150px;
  border: solid 1px #fa9d08;
  padding-left: 10px;
  padding-right: 10px;
}

.options .vote:hover {
  background: #ca9d4a;
  color: #fff;
  box-shadow: #00000099 1px 1px 1px;
}

.options .vote:active {
  background: #ca9d4a;
  color: #fff;
  box-shadow: inset #000000CC 1px 1px 1px;
}

.option .header {
  font-weight: 900;
  font-size: 1.5em;
  text-transform: uppercase;
}

.option .subhead {
  font-size: 1.1em;
}

.option .thumbnail img {
  width: 100%;
  margin-top: 10px;
}

.terms {
  display: none;
  color: #ffffff99;
}

.terms.show {
  display: block;
}


.voted {
  max-width: 0;
  overflow: hidden;
}

/* CHECKMARK */
.checkmark__circle {
  stroke-dasharray: 216; /* ORIGINALLY 166px */
  stroke-dashoffset: 216; /* ORIGINALLY 166px */
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
}

.checkmark {
  width: 45px; /* ORIGINALLY 56px */
  height: 45px; /* ORIGINALLY 56px */
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 98; /* ORIGINALLY 48px */
  stroke-dashoffset: 98; /* ORIGINALLY 48px*/
}

.options.done .yes .voted .checkmark__circle {
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.options.done .yes .voted .checkmark {
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.options.done .yes .voted .checkmark__check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.options.done .vote {
  width:0;
  overflow: hidden;
  transition: all 0.3s;
  padding-left:0;
  padding-right:0;
  border:0;
}

.options.done .yes .voted {
  max-width: 200px;
  overflow: hidden;
}

#follow {
  padding:20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fa9d0899;
  margin-top:10px;
  text-align: center;
}

#follow .click {
  font-size:1.5em;
  font-weight: bold;
}

#follow a {
  font-weight: bold;
  font-size:30px;
  text-decoration: none !important;
  color: #000;
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#follow a div {
  font-size:18px;
}

#follow img {
  height:36px;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px #7ac142;
  }
}

/* ==========================================================================
     Helper classes
     ========================================================================== */

.hidden,
[hidden] {
  display: none !important;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}

.clearfix::after {
  clear: both;
}

@media only screen and (min-width: 1024px) {
  body {
    font-size: 1.25em;
  }
  #container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .options {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }

  .options .option {
    margin: 10px;
    box-shadow: #00000033 3px 3px 3px;
  }

  #terms {
    max-width: 900px;
  }
}
